<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Einstellungen für das Wertermittlungsformular auf Ihrer Website</strong>
              <br>
              <p>In diesem Bereich konfigurieren Sie das Aussehen sowie die Inhalte des Leadgenerators auf Ihrer Webseite</p>
            </slot>
          </CCardHeader>
          <CCardBody v-if="isNotKensingtonOrIsButOrgaAdmin">
            <strong>Installation des Leadgenerator auf Ihre Webseite</strong>
            <p>Fügen Sie diesen Integrationscode einfach per Copy & Paste zu jeder Seite, auf der das Wertermittlungsformular angezeigt werden soll auf Ihrer Internetpräsenz hinzu.</p>
            <textarea readonly="" type="text" class="form-control">
            &lt;script type=&quot;text/javascript&quot; src=&quot;{{ iframeUrl }}/script?key={{ iframeKey }}&quot;&gt;&lt;/script&gt;
              &lt;div id=&quot;prt-iframe-placeholder&quot;&gt;&lt;/div&gt;</textarea>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>iFrame Einstellungen</strong>
              <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px" style="float: right"/>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CTabs>
              <CTab>
                <template slot="title">
                  Grundeinstellungen
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                            type="text"
                            label="Google Maps API"
                            description="Geben Sie hier bitte den Schlüssel (Key) für die Google Maps API ein."
                            v-model="iframeSettings.googleMapsApiKey"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                            horizontal
                    />
                    <CRow v-if="isNotKensingtonOrIsButOrgaAdmin">
                      <CCol sm="3"></CCol>
                      <CCol sm="9" style="padding-left: 11px; margin-bottom: 15px;">
                        <div>
                          <p><CButton
                                  @click="warningModal = true"
                                  color="primary"
                          >
                            Google Maps API einrichten (Anleitung)
                          </CButton></p>
                          <CModal
                                  title="Google Maps API einrichten"
                                  color="warning"
                                  :show.sync="warningModal"
                          >
                            <p>1. )
                              <strong>Anmeldung</strong></p>
                            <p>Melde dich mit deinen Kontodaten bei Google ein.</p>
                            <p><strong>2. Google Maps Platform aktivieren</strong></p>
                            <p>Auf der Seite <a href="https://cloud.google.com/maps-platform/">https://cloud.google.com/maps-platform/</a> klickst du auf “jetzt starten” und wählst bei Produkt “Maps” aus.</p>
                            <div><img src="https://downloads.intercomcdn.com/i/o/230667105/19c290176b17aa96f20b1d9d/maps-start.png" alt="" width="450" data-link-url="" data-height="400"></div>
                            <p><strong>3. Projekt anlegen</strong></p>
                            <p>Nun wählst du bei Produkte auswählen Maps aus, nach einem Klick auf “Weiter”
                              <br> kannst du dein Projekt anlegen und benennen.</p>
                            <br>
                            <div><img src="https://downloads.intercomcdn.com/i/o/230669695/107f53d27e14f539cb687770/maps2.png" alt="" width="450" data-link-url="" data-height="1562"></div>
                            <br>
                            <p><strong>4. Projekt verwalten</strong></p>
                            <p>Unter <a href="https://accounts.google.com/signin/v2/identifier?service=cloudconsole&amp;passive=1209600&amp;osid=1&amp;continue=https%3A%2F%2Fconsole.cloud.google.com%2Fgoogle%2Fmaps-apis%2Foverview&amp;followup=https%3A%2F%2Fconsole.cloud.google.com%2Fgoogle%2Fmaps-apis%2Foverview&amp;flowName=GlifWebSignIn&amp;flowEntry=ServiceLogin">https://console.cloud.google.com/google/maps-apis/overview</a>
                              kannst du nun deine Projekte und auch die Google Maps Api Keys verwalten.</p>
                            <br>
                            <p>Diese Funktion ist vor allem nützlich, wenn Du mehrere Webprojekte hast. Somit kannst Du für jede Seite oder jeden Kunden einen eigenen API Key anlegen.</p>
                            <p>In der Übersicht wirst gleich darauf hingewiesen, deinen Api Key abzusichern.</p>
                            <br>
                            <p><strong>5. APIs Aktivieren</strong></p>
                            <p>Bitte aktiviere die folgenden APIs: </p>
                            <p>Google Maps API und<br> Maps Embed API<br> Google Places API</p>
                          </CModal>
                        </div>
                      </CCol>
                    </CRow>
                    <!--<CInput
                            type="text"
                            label="Phone Validator API"
                            description="Zum validieren der Telefonnummern können Sie die Services des Drittanbieters phone-validator.net nutzen. Zur Erstellung des API Keys, klicken Sie bitte hier. Wenn Sie dieses Feld leer lassen, ist die Validation deaktiviert."
                            horizontal
                    />-->
                    <WysiwygEditor
                            label="Vorteile"
                            description='Tragen Sie hier Ihre Alleinstellungsmerkmale ein, diese werden im letzten Schritt angezeigt. HTML ist erlaubt. Nutzen Sie eine Auflistung um später im Leadgenerator Haken zu erzeugen.'
                            v-model="iframeSettings.benefits"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                            horizontal
                    />
                    <WysiwygEditor
                            label="Datenschutzerklärung"
                            description="Tragen Sie hier Ihre vollständige Datenschutzerklärung ein."
                            v-model="iframeSettings.privacyPolicy"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                            horizontal
                    />
                    <Checkbox
                            label="Aktive Immobilientypen"
                            name="activeSteps"
                            :options="[{label: 'Wohnung', value: 'apartment'}, {label: 'Haus', value: 'house'}, {label: 'Grundstück (keine Wertermittlung)', value: 'land'}, {label: 'Miete', value: 'rent'}, {label: 'Gewerbe (keine Wertermittlung)', value: 'business'}]"
                            v-model="iframeSettings.activeSteps"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <CInput
                            type="text"
                            label="Weiterleitungs URL"
                            description="Sollen die Kund:innen nach Absenden des Leadgenerators auf eine andere Seite weitergeleitet werden? Wenn Ja, geben Sie die URL der Seite an."
                            v-model="iframeSettings.redirectOnSuccessUrl"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                            horizontal
                    />
                    <RadioButton
                            label="Wertermittlung durchführen?"
                            name="rateProperty"
                            description="Soll nach Absenden des Formulars der Wert der Immobilie ermittelt werden?"
                            :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                            v-model="iframeSettings.rateProperty"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                     <RadioButton
                            label="PDF an Eigentümer senden?"
                            name="sendPdf"
                            description="Soll nach Absenden des Formulars die Wohnmarktanalyse an den Eigentümer gesendet werden?"
                            :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                            v-model="iframeSettings.sendPdfCustomer"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <RadioButton
                            label="Leadfreigabe aktivieren?"
                            name="queueActive"
                            description="Sollen alle Leads vor dem Versand an die Kund:innen überprüft werden? (Versand erst nach Ihrer Freigabe)"
                            :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                            v-model="iframeSettings.queueActive"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <RadioButton
                            label="Frage nach dem Grund der Bewertung aktivieren?"
                            name="askReason"
                            description="Soll nach dem Grund für die Immobilienbewertung gefragt werden? (Kauf oder Verkauf)"
                            :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                            v-model="iframeSettings.askReason"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <RadioButton
                            label="Möchten Sie die Telefonverifizierung aktivieren?"
                            name="smsVerifyActive"
                            description="Jede Telefonnummer muss mit einem SMS oder Telefon Code verifiziert werden. Ohne Verifizierung erfolgt keine Wertermittlung"
                            :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                            v-model="iframeSettings.smsVerifyActive"
                            v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <AddableList
                        label="Fragen zur Qualifizierung Ihrer Leads"
                        description="Hinterlegen Sie individuelle Fragen zur Vorqualifizierung ihrer potenziellen Kunden"
                        v-model="iframeSettings.reasonsWhenBuying"
                        v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <AddableList
                        label="Fragen zur Qualifizierung Ihrer Leads (Miete)"
                        description="Hinterlegen Sie individuelle Fragen zur Vorqualifizierung ihrer potenziellen Kunden"
                        v-model="iframeSettings.reasonsWhenRenting"
                        v-if="isNotKensingtonOrIsButOrgaAdmin"
                    />
                    <CInput
                        type="number"
                        label="Globaler Auf- und Abschlag (%)"
                        description="Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst"
                        v-model="iframeSettings.globalMarkUpOrDown"
                        horizontal
                    />
                    <CInput
                        type="number"
                        label="Verkauf: Auf- und Abschlag (%)"
                        description='Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst, wenn der Typ des Leads "Verkauf" ist. Dieser Wert überschreibt den globalen Auf- und Abschlag.'
                        v-model="iframeSettings.markUpOrDownForBuy"
                        horizontal
                    />
                    <CInput
                        type="number"
                        label="Miete: Auf- und Abschlag (%)"
                        description='Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst, wenn der Typ des Leads "Miete" ist. Dieser Wert überschreibt den globalen Auf- und Abschlag.'
                        v-model="iframeSettings.markUpOrDownForRent"
                        horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Schriften
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <PrtFontPicker
                            label="Schriftart"
                            description="Hier können Sie die allgemeine Schriftart abändern."
                            v-model="iframeSettings.fontFamily"
                            name="fontFamily"
                    />
                    <CInput
                            type="text"
                            label="Schriftgröße"
                            description="Hier können Sie die allgemeine Schriftgröße abändern."
                            v-model="iframeSettings.fontSize"
                            horizontal
                    />
                    <ColorPicker
                            label="Schriftfarbe"
                            description="Hier können Sie die allgemeine Schriftfarbe abändern."
                            v-model="iframeSettings.fontColor"
                    />
                    <RadioButton
                            label="Schrifttransformation"
                            name="textTransformations"
                            :options="[{ label: 'GROSSBUCHSTABEN', value: 'uppercase' }, { label: 'kleinbuchstaben', value: 'lowercase' }, { label: 'Erster Buchstabe Groß', value: 'capitalize' }, { label: 'Keine Transformation', value: 'none'}]"
                            description="Hier können Sie bestimmen wie der Text transformiert weden soll."
                            v-model="iframeSettings.textTransformations"
                      />
                    <RadioButton
                            label="Schriftstärke"
                            name="fontWeight"
                            :options="[{label: 'Light (300)', value: '300'}, {label: 'Normal (400)', value: '400'}, {label: 'Bold (700)', value: '700'}]"
                            description="Hier können Sie die allgemeine Schriftstärke abändern."
                            v-model="iframeSettings.fontWeight"
                      />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Überschriften
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                            type="text"
                            label="Titel - H1: Schriftgröße"
                            v-model="iframeSettings.h1Size"
                            horizontal
                    />
                    <ColorPicker
                            label="Titel - H1: Farbe"
                            v-model="iframeSettings.h1Color"
                    />
                    <CInput
                            type="text"
                            label="Titel - H2: Schriftgröße"
                            value="inherit"
                            v-model="iframeSettings.h2Size"
                            horizontal
                    />
                    <ColorPicker
                            label="Titel - H2: Farbe"
                            v-model="iframeSettings.h2Color"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Individualisieren
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CodeEditor
                            label="Eigenes CSS"
                            description="Individuelles CSS Angaben überschreiben das Theme-Stylesheet. Nutzen Sie !important, falls notwendig."
                            v-model="iframeSettings.customCss"
                            language="css"
                            horizontal
                    />
                    <WysiwygEditor
                            label="Extra Schritt"
                            description="Erstellen Sie ein Zwischenschritt vor dem Endergebnis. Leer lassen um es zu deaktivieren."
                            v-model="iframeSettings.extraSlide"
                            horizontal
                    />
                    <CInput
                            label="Extra Schritt: URL"
                            description="Wenn Sie einen Extra Schritt eingerichtet haben, können Sie auch die URL dazu ändern. Wenn die Kund:innen auf dem Extra Schritt landen wird die URL dann in zB. https://www.ihre-url.de/dieser-wert geändert"
                            v-model="iframeSettings.extraSlideRoute"
                            horizontal
                    />
                    <GoogleMapsAutocompleteInput
                            label="Google Maps: Startpunkt"
                            description="Bitte geben Sie die Startadresse für Google Maps ein. Hier können Sie auch eine genau Adresse eingeben mit Straße und Hausnummer."
                            v-model="iframeSettings.mapCenter.address"
                            @placechanged="saveMapsCenter"
                            placeholder="Bitte geben Sie die Startadresse für Google Maps ein..."
                            :country="['de', 'at', 'ch']"
                            types=""
                            horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Farben
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <ColorPicker
                            label="Primäre Farbe"
                            description="Hier können Sie die primäre Farbe ändern."
                            v-model="iframeSettings.primaryColor"
                    />
                    <ColorPicker
                            label="Sekundäre Farbe"
                            description="Hier können Sie die sekundäre Farbe ändern."
                            v-model="iframeSettings.secondaryColor"
                    />
                    <ColorPicker
                            label="Button (Zurück): Farbe"
                            description="Hier können Sie die Button-Farbe für 'Zurück' abändern"
                            v-model="iframeSettings.previousButtonColor"
                    />
                    <ColorPicker
                            label="Button (Weiter): Farbe"
                            description="Hier können Sie die Button-Farbe für 'Weiter' abändern"
                            v-model="iframeSettings.nextButtonColor"
                    />
                    <ColorPicker
                            label="Button (Absenden): Farbe"
                            description="Hier können Sie die Button-Farbe für 'Absenden' abändern"
                            v-model="iframeSettings.submitButtonColor"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Textanpassung
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                            label="Start"
                            description="Ändern Sie den Text auf der Startseite"
                            v-model="iframeSettings.text.start"
                            horizontal
                    />
                    <CInput
                            label="Start: Slogan 1"
                            description="Ändern Sie den Slogan auf der Startseite"
                            v-model="iframeSettings.text.startSlogan1"
                            horizontal
                    />
                    <CInput
                            label="Start: Slogan 2"
                            description="Ändern Sie den Slogan auf der Startseite"
                            v-model="iframeSettings.text.startSlogan2"
                            horizontal
                    />
                    <CInput
                            label="Start: Button"
                            description="Ändern Sie den Text des Buttons auf der Startseite"
                            v-model="iframeSettings.text.startButton"
                            horizontal
                    />
                    <CInput
                            label="Region"
                            description="Ändern Sie den Text für den Standort der Immobilie"
                            v-model="iframeSettings.text.region"
                            horizontal
                    />
                    <CInput
                            label="Formular"
                            description="Ändern Sie den Text über dem Kontaktformular."
                            v-model="iframeSettings.text.personalDataForm"
                            horizontal
                    />
                    <CInput
                            label="Schlusssatz"
                            description="Ändern Sie den Dankessatz bei der Wertermittlung"
                            v-model="iframeSettings.text.thanks"
                            horizontal
                    />
                    <CInput
                            label="Deaktivierte Wertermittlung Zeile 1"
                            description="Ändern Sie den Text der 1 Zeile bei deaktivierter Wertermittlung"
                            v-model="iframeSettings.text.deactivatedRate1"
                            horizontal
                    />
                    <CInput
                            label="Deaktivierte Wertermittlung Zeile 2"
                            description="Ändern Sie den Text der 2 Zeile bei deaktivierter Wertermittlung"
                            v-model="iframeSettings.text.deactivatedRate2"
                            horizontal
                    />
                    <CInput
                            label="Fehlgeschlagene Wertermittlung Zeile 1"
                            description="Ändern Sie den Text der 1 Zeile bei fehlgeschlagener Wertermittlung"
                            v-model="iframeSettings.text.notFound1"
                            horizontal
                    />
                    <CInput
                            label="Fehlgeschlagene Wertermittlung Zeile 2"
                            description="Ändern Sie den Text der 2 Zeile bei fehlgeschlagener Wertermittlung"
                            v-model="iframeSettings.text.notFound2"
                            horizontal
                    />
                    <CInput
                            label="Zusatztext"
                            description="Dieser Text wird bei einer erfolgreichen Wertermittlung angezeigt"
                            v-model="iframeSettings.text.extraMessage"
                            horizontal
                    />
                    <CInput
                            label="Grund der Bewertung"
                            description="Dieser Text wird vor allen Schritten angezeigt"
                            v-model="iframeSettings.text.reason"
                            horizontal
                    />
                    <CInput
                            label="Verkaufszeitwunsch"
                            :description='`Dieser Text wird nach dem "Grund der Bewertung" angezeigt, wenn "Verkaufen" ausgewählt wird.`'
                            v-model="iframeSettings.text.reasonWhen"
                            horizontal
                    />
                    <CInput
                            label="Der Wert Ihrer Immobilie wird ermittelt"
                            description="Dieser Text wird im Formular für die Kontaktdaten des Eigentümers angezeigt"
                            v-model="iframeSettings.text.propertyIsRating"
                            horizontal
                    />
                    <CInput
                            label="Der Wert Ihrer Immobilie wurde ermittelt"
                            description="Dieser Text wird im Formular für die Kontaktdaten des Eigentümers angezeigt"
                            v-model="iframeSettings.text.propertyRated"
                            horizontal
                    />
                    <CInput
                            label="Der Wert Ihrer Immobilie beträgt"
                            :description="'Dieser Text wird bei einer erfolgreichen Wertermittlung angezeigt. Hier müssen die Variable {{ WERT }} angeben'"
                            v-model="iframeSettings.text.propertyValueIs"
                            horizontal
                    />
                    <CInput
                            label="E-Mail: Titel"
                            description="Ändern Sie den Titel für den E-Mail Schritt."
                            v-model="iframeSettings.text.emailTitle"
                            horizontal
                    />
                    <CInput
                            label="E-Mail: Inhalt"
                            description="Ändern Sie den Text für den E-Mail Schritt."
                            v-model="iframeSettings.text.emailBody"
                            horizontal
                    />
                    <hr/>
                    <CInput
                            label="Wohnung: Wohnfläche"
                            description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Wohnung"
                            v-model="iframeSettings.text.apartment.livingArea"
                            horizontal
                    />
                    <CInput
                            label="Wohnung: Etage"
                            description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Wohnung"
                            v-model="iframeSettings.text.apartment.floor"
                            horizontal
                    />
                    <CInput
                            label="Wohnung: Zimmer"
                            description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Wohnung"
                            v-model="iframeSettings.text.apartment.roomCount"
                            horizontal
                    />
                    <CInput
                            label="Wohnung: Baujahr"
                            description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Wohnung"
                            v-model="iframeSettings.text.apartment.constructionYear"
                            horizontal
                    />
                    <hr/>
                    <CInput
                            label="Haus: Grundfläche"
                            description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Haus"
                            v-model="iframeSettings.text.house.siteArea"
                            horizontal
                    />
                    <CInput
                            label="Haus: Art"
                            description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Haus"
                            v-model="iframeSettings.text.house.houseType"
                            horizontal
                    /><CInput
                            label="Haus: Wohnfläche"
                            description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Haus"
                            v-model="iframeSettings.text.house.livingArea"
                            horizontal
                    />
                    <CInput
                            label="Haus: Etage"
                            description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Haus"
                            v-model="iframeSettings.text.house.floor"
                            horizontal
                    />
                    <CInput
                            label="Haus: Zimmer"
                            description="Ändern Sie den Text für Schritt 5 bei dem Immobilientyp Haus"
                            v-model="iframeSettings.text.house.roomCount"
                            horizontal
                    />
                    <CInput
                            label="Haus: Baujahr"
                            description="Ändern Sie den Text für Schritt 6 bei dem Immobilientyp Haus"
                            v-model="iframeSettings.text.house.constructionYear"
                            horizontal
                    />
                    <hr/>
                    <CInput
                            label="Grundstück: Grundfläche"
                            description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Grundstück"
                            v-model="iframeSettings.text.land.siteArea"
                            horizontal
                    />
                    <CInput
                            label="Grundstück: Erschlossen"
                            description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Grundstück"
                            v-model="iframeSettings.text.land.developedProperty"
                            horizontal
                    />
                    <CInput
                            label="Grundstück: Bebauungsmöglichkeiten"
                            description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Grundstück"
                            v-model="iframeSettings.text.land.constructionPossibilities"
                            horizontal
                    />
                    <CInput
                            label="Grundstück: Grundstückszuschnitt"
                            description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Grundstück"
                            v-model="iframeSettings.text.land.siteLayout"
                            horizontal
                    />
                    <hr/>
                    <CInput
                            label="Miete: Wohnfläche"
                            description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Miete"
                            v-model="iframeSettings.text.rent.livingArea"
                            horizontal
                    />
                    <CInput
                            label="Miete: Typ"
                            description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Miete"
                            v-model="iframeSettings.text.rent.rentType"
                            horizontal
                    />
                    <CInput
                            label="Miete: Kategorie"
                            description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Miete"
                            v-model="iframeSettings.text.rent.rentCategory"
                            horizontal
                    />
                    <CInput
                            label="Miete: Baujahr"
                            description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Miete"
                            v-model="iframeSettings.text.rent.constructionYear"
                            horizontal
                    />
                    <hr/>
                    <CInput
                        label="Gewerbe: Art"
                        description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Gewerbe"
                        v-model="iframeSettings.text.business.businessType"
                        horizontal
                    />
                    <CInput
                        label="Gewerbe: Typ"
                        description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Gewerbe"
                        v-model="iframeSettings.text.business.livingArea"
                        horizontal
                    />
                    <CInput
                        label="Gewerbe: Kategorie"
                        description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Gewerbe"
                        v-model="iframeSettings.text.business.siteArea"
                        horizontal
                    />
                    <CInput
                        label="Gewerbe: Baujahr"
                        description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Gewerbe"
                        v-model="iframeSettings.text.business.isRent"
                        horizontal
                    />
                    <CInput
                        label="Gewerbe: Baujahr"
                        description="Ändern Sie den Text für Schritt 5 bei dem Immobilientyp Gewerbe"
                        v-model="iframeSettings.text.business.constructionYear"
                        horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Tracking
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <CButton
                            @click="trackingYoutubeCollapse = !trackingYoutubeCollapse"
                            color="primary"
                            class="mb-2"
                    >
                      Anleitung für das Tracking
                    </CButton>
                    <CCollapse :show="trackingYoutubeCollapse">
                      <CCard body-wrapper>
                        <CEmbed
                                tag="p"
                                aspect="16by9"
                                src="https://www.youtube.com/embed/8Vr_iSaaMnI"
                                frameborder="0"
                                allow="accelerometer;autoplay; encrypted-media; gyroscope; picture-in-picture"
                        />
                      </CCard>
                    </CCollapse>
                    <hr class="mt-2 mb-4"/>
                  </CCol>
                  <CCol xs="12" xl="10">
                    <Checkbox
                            label="Tracking via"
                            :options="[{label: 'Google Tag Manager', value: 'google_tag_manager'}, {label: 'Facebook Pixel', value: 'facebook_pixel'}]"
                            description="Hier können Sie einstellen welche Trackingmethode Sie benutzen möchten."
                            v-model="iframeSettings.trackingVia"
                            horizontal
                    />
                    <CInput
                            label="Facebook Pixel: ID"
                            description="Geben Sie ein welche Pixel ID sie für die Wertermittlung in Facebook Pixel nutzen."
                            v-model="iframeSettings.facebookPixelId"
                            horizontal
                    />
                    <CInput
                            label="Google Tag Manager: ID"
                            description="Geben Sie die Google Tag Manager ID ein, die Sie von Google bekommen haben."
                            v-model="iframeSettings.googleTagManagerId"
                            horizontal
                    />
                    <CodeEditor
                        label="Eigenen Header"
                        description="Individuelles HTML in den <head> der Wertermittlung einfügen."
                        v-model="iframeSettings.customHeader"
                        language="markup"
                        horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  DSGVO
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <RadioButton
                        label="Einwilligung zum Datenschutz aktivieren?"
                        name="dsgvoActive"
                        description="Der Nutzer wird detailliert über die Dienste informiert, die Cookies setzen und Daten übertragen. Er muss vor Nutzung ausdrücklich bestätigen, dass er zustimmt."
                        :options="[{label: 'Ja', value: true}, {label: 'Nein', value: false}]"
                        v-model="iframeSettings.dsgvo.active"
                    />
<!--                <CInput
                        type="text"
                        label="Firmenname"
                        description="Wird für die Datenschutzerklärung verwendet"
                        v-model="iframeSettings.dsgvo.agentName"
                        horizontal
                    />
                    <CInput
                        type="text"
                        label="Straße und Hausnummer"
                        description="Wird für die Datenschutzerklärung verwendet"
                        v-model="iframeSettings.dsgvo.address"
                        horizontal
                    />
                    <CInput
                        type="text"
                        label="PLZ"
                        description="Wird für die Datenschutzerklärung verwendet"
                        v-model="iframeSettings.dsgvo.plz"
                        horizontal
                    />
                    <CInput
                        type="text"
                        label="Ort"
                        description="Wird für die Datenschutzerklärung verwendet"
                        v-model="iframeSettings.dsgvo.place"
                        horizontal
                    />
                    <CInput
                        type="text"
                        label="Telefon"
                        description="Wird für die Datenschutzerklärung verwendet"
                        v-model="iframeSettings.dsgvo.phone"
                        horizontal
                    />-->
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title">
                  Extra Schritte
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="12">
                    <ExtraStepsComponent v-model="iframeSettings.extraSteps"/>
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  Vorschau
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <div style="margin-bottom: 4px">
                      <CButton @click="refreshPreviewIframe" color="warning">Neu laden</CButton>
                    </div>
                    <div>
                      <iframe
                          ref="previewLeadGeneratorIframe"
                          src="about:blank"
                          frameborder="0"
                          style="border: 0;width: 100%;height: 700px"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
            <CButton @click="submitSettings" color="success">Speichern</CButton>
            <CButton
                v-if="$commons.isOrganizationAdmin()"
                @click="submitSettings(true)"
                style="margin-left: 6px"
                v-c-tooltip="'Legen Sie die aktuellen Einstellungen als Standard für alle zukünftigen Standorte fest'"
                color="warning">
              Als Standard definieren & Speichern
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import WysiwygEditor from '@/components/WysiwygEditor'
import CodeEditor from '@/components/CodeEditor'
import Checkbox from '@/components/Checkbox'
import ColorPicker from '@/components/ColorPicker'
import RadioButton from '@/components/RadioButton'
import commons from '@/commons'
import axios from 'axios'
import _ from 'lodash'
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import PrtFontPicker from '@/components/PrtFontPicker'
import GoogleMapsAutocompleteInput from '@/components/GoogleMapsAutocompleteInput'
import AddableList from "@/components/AddableList";
import ExtraStepsComponent from "@/components/ExtraStepsComponent";
export default {
  name: 'IframeSettings',
  components: {
    ExtraStepsComponent,
    AddableList,
    GoogleMapsAutocompleteInput,
    PrtFontPicker,
    DoubleBounceLoader,
    RadioButton,
    ColorPicker,
    Checkbox,
    WysiwygEditor,
    CodeEditor
  },
  mounted () {
    this.loading = true
    axios.get('/settings')
      .then(response => {
        this.iframeSettings = _.merge({}, this.iframeSettings, commons.deepenOneLevelToNested(response.data, '_', 'iframe_'))
        if (typeof this.iframeSettings.activeSteps === 'string') this.iframeSettings.activeSteps = JSON.parse(this.iframeSettings.activeSteps); // this fields are saved as JSON string, so we pars
        if (typeof this.iframeSettings.trackingVia === 'string') this.iframeSettings.trackingVia = JSON.parse(this.iframeSettings.trackingVia); // this fields are saved as JSON string, so we pars
        if (typeof this.iframeSettings.reasonsWhenBuying === 'string') this.iframeSettings.reasonsWhenBuying = JSON.parse(this.iframeSettings.reasonsWhenBuying); // this fields are saved as JSON string, so we pars
        if (typeof this.iframeSettings.reasonsWhenRenting === 'string') this.iframeSettings.reasonsWhenRenting = JSON.parse(this.iframeSettings.reasonsWhenRenting); // this fields are saved as JSON string, so we pars
        if (typeof this.iframeSettings.extraSteps === 'string') this.iframeSettings.extraSteps = JSON.parse(this.iframeSettings.extraSteps); // this fields are saved as JSON string, so we pars
        this.loading = false
        if (this.isNotKensingtonOrIsButOrgaAdmin) {
          setTimeout(this.refreshPreviewIframe, 20)
        }
      })
      .catch(err => console.error(err))
  },
  methods: {
    submitSettings(sendAsDefault) {
      this.saving = true
      let toSubmit = commons.oneLevelObject(this.iframeSettings, '_', 'iframe_')
      const url = sendAsDefault === true ? '/settings/default' : '/settings'
      axios.post(url, toSubmit)
        .then(response => {
          this.$toaster.makeToast('Settings saved successfully', 'Your settings has been saved successfully in the cloud.')
          this.saving = false
        })
        .catch(err => {
          if (err.response.status)
          console.error(err)
        })
    },
    saveMapsCenter (changedPlace) {
      this.iframeSettings.mapCenter.lat = changedPlace.latitude
      this.iframeSettings.mapCenter.lng = changedPlace.longitude
    },
    refreshPreviewIframe() {
      let iframe = this.$refs.previewLeadGeneratorIframe
      let providedSettings = commons.oneLevelObject(this.iframeSettings, '_', 'iframe_')
      providedSettings['iframe_dontTrackPreview'] = '1'
      this.saving = true
      axios.post(process.env.VUE_APP_IFRAME_URL + `/preview?key=${this.iframeKey}`, providedSettings)
          .then(response => {
            let htmlContent = response.data
            iframe.contentWindow.document.open()
            iframe.contentWindow.document.write(htmlContent)
            iframe.contentWindow.document.close()
          })
          .catch(err => {
            if (err.response.status)
              console.error(err)
          })
          .finally(() => {
            this.saving = false
          })
    }
  },
  data () {
    return {
      warningModal: false,
      loading: false,
      saving: false,
      iframeUrl: process.env.VUE_APP_IFRAME_URL,
      iframeKey: commons.getFromStorage('currentRealm').iframeKey,
      trackingYoutubeCollapse: false,
      /* v-if explained: if realmPlain is NOT KENSINGTON then show, else: check if hasRole minimum ORGANIZATION_ADMIN */
      isNotKensingtonOrIsButOrgaAdmin: !commons.hasRealmPlan('KENSINGTON') || commons.hasRole('ORGANIZATION_ADMIN'),
      iframeSettings: {
        rateProperty: true,
        googleMapsApiKey: '',
        benefits: '<ul> <li>Regionale Kompetenz</li> <li>Garantiert kostenlose Immobilienbewertung</li> <li>Hohe Kundenzufriedenheit der Eigentümer</li> </ul>',
        privacyPolicy: '<h2>Datenschutzerklärung</h2><p>Fügen Sie bitte Ihre vollständige Datenschutzerklärung ein...</p>',
        activeSteps: ['apartment', 'house', 'land', 'rent'],
        redirectOnSuccessUrl: '',
        queueActive: false,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontColor: '#0a0a0a',
        textTransformations: 'none',
        fontWeight: '400',
        h1Size: '24px',
        h1Color: '#000',
        h2Size: '18px',
        h2Color: '#000',
        customCss: '',
        extraSlide: '',
        extraSlideRoute: '',
        primaryColor: '#002335',
        secondaryColor: '#17b5a0',
        previousButtonColor: '#fc5c65',
        nextButtonColor: '#20bf6b',
        submitButtonColor: '#20bf6b',
        askReason: true,
        sendPdfCustomer: true,
        smsVerifyActive: false,
        reasonsWhenBuying: ['Verkauf', 'Kauf'],
        reasonsWhenRenting: ['Vermieten', 'Mieten'],
        mapCenter: {
          address: 'Berlin',
          lat: 52.520008,
          lng: 13.404954
        },
        globalMarkUpOrDown: 0,
        markUpOrDownForBuy: 0,
        markUpOrDownForRent: 0,
        text: {
          start: 'Welche Immobilie möchten Sie <b>verkaufen?</b>',
          startSlogan1: '100% <span class="prt-color-secondary">kostenlos</span> bewerten lassen',
          startSlogan2: 'Welche Immobilie möchten Sie bewerten?',
          startButton: 'Jetzt Bewertung anfordern!',
          region: 'In welcher <b>Region</b> befindet sich die Immobilie?',
          personalDataForm: 'Fordern Sie jetzt Ihr Angebot von makler-anfragen.immo an.',
          thanks: 'Wir melden uns bei Ihnen, so schnell wie möglich!',
          deactivatedRate1: 'Danke für Ihr Vertrauen!',
          deactivatedRate2: 'Wir melden uns so bald wie möglich!',
          notFound1: 'Leider konnten wir anhand Ihrer Daten keine Bewertung generieren.!',
          notFound2: 'Wir melden uns so bald wie möglich',
          extraMessage: '',
          reason: 'Wozu benötigen Sie die Immobilienbewertung?',
          reasonWhen: 'Wann möchten Sie voraussichtlich verkaufen?',
          propertyIsRating: 'Der Wert Ihrer Immobilie wird ermittelt...',
          propertyRated: 'Der Wert Ihrer Immobilie wurde ermittelt!',
          propertyValueIs: 'Ihr Immobilienwert beträgt {{ WERT }}',
          emailTitle: 'An wen dürfen wir die <b>Bewertung</b> versenden?',
          emailBody: 'Bitte achten Sie auf die korrekte Schreibweise Ihrer E-Mail-Adresse.<br><br><b>Wichtig:</b> Überprüfen Sie auch Ihren Spam-Ordner.',

          apartment: {
            livingArea: 'Wie ist die <b>Wohnfläche</b> der Wohnung?',
            floor: 'In welcher <b>Etage</b> befindet sich die Wohnung?',
            roomCount: 'Wie viele <b>Zimmer</b> hat die Wohnung?',
            constructionYear: 'Wann wurde das Wohnhaus <b>gebaut?</b>'
          },

          house: {
            siteArea: 'Welche Fläche hat das <b>Grundstück</b> des Hauses?',
            houseType: 'Bitte wählen Sie die Art des Hauses aus.',
            livingArea: 'Wie ist die gesamte <b>Wohnfläche</b> des Hauses?',
            floor: 'Wie viele <b>Etagen</b> hat das Haus?',
            roomCount: 'Wie viele <b>Zimmer</b> hat das Haus?',
            constructionYear: 'Wann wurde das <b>Haus</b> gebaut?'
          },

          land: {
            siteArea: 'Welche Fläche hat das <b>Grundstück</b>?',
            developedProperty: 'Ist das Grundstück <b>erschlossen</b>?',
            constructionPossibilities: 'Wie sind die <b>Bebauungsmöglichkeiten</b>?',
            siteLayout: 'Wie ist der <b>Grundstückszuschnitt</b>?'
          },

          rent: {
            livingArea: 'Wie ist die <b>Wohnfläche</b> der Wohnung?',
            rentType: 'Um was <b>handelt</b> es sich hierbei?',
            rentCategory: 'Welcher <b>Typ</b> passt zur Ihrer Immobilie?',
            constructionYear: 'Wann wurde das Wohnhaus <b>gebaut?</b>'
          },

          business: {
            businessType: 'Um welche Gebäudeart handelt es sich?',
            livingArea: 'Wie groß ist die Gewerbefläche der Immobilie?',
            siteArea: 'Welche Fläche hat das Grundstück des Gewerbes?',
            isRent: 'Ist die Immobilie vermietet?',
            constructionYear: 'Wann wurde das Haus erbaut?'
          }
        },
        dsgvo: {
          active: false,
          agentName: '',
          address: '',
          plz: '',
          place: '',
          phone: ''
        },
        trackingVia: [],
        facebookPixelId: '',
        googleTagManagerId: '',
        customHeader: '',
        extraSteps: []
      }
    }
  }
}
</script>

<style>
  .api-key-input {
    padding: 9px 10px;
    background-color: #eaf0f6;
    box-shadow: none;
    min-height: 155px;
    border-radius: 3px;
    border: 1px solid #cbd6e2;
    font-size: 16px;
    transition: all .15s ease-out;
    color: #33475b;
    line-height: 22px;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    font-family: Source Code Pro,Consolas,Monaco,Courier New,monospace;
    font-weight: 500;

  }
</style>

<style scoped>

</style>
