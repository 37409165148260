<template>
  <div>
    <label>{{ label }}</label>
    <CRow>
      <CCol v-if="horizontal" sm="3"/>
      <CCol v-if="answerList && answerList.length > 0" :sm="horizontal ? 9 : 12">
        <div class="extra-steps-add-step-list-wrapper">
          <div v-for="(item, index) in answerList" class="extra-steps-add-step-list-item">
            <div @click="removeFromList(index)" class="extra-steps-add-step-list-item-icon">
              <CIcon name="cil-trash" height="16"/>
            </div>
            {{ item.answer }} ({{ item.change > 0 ? '+' : '' }}{{ item.change}}{{ determineChangeType(item.changeType).sign }})
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs="6" sm="6" style="padding-right: 10px;">
        <CSelect :value="changeType" @update:value="changeType = $event" :options="changeTypeOptions" />
      </CCol>
      <CCol xs="6" sm="6" style="padding-left: 10px;">
        <CInput
            type="number"
            step="1"
            :horizontal="horizontal"
            v-model="changeValue"
            invalidFeedback="Bitte geben Sie hier einen numerischen Wert ein!"
            :isValid="changeValueValid"
            placeholder="...zB. 10"
            required
            custom>
        </CInput>
      </CCol>
      <CCol xs="12" sm="12">
        <CInput
            :description="description"
            v-model="inputValue"
            @keyup.enter="addToList"
            :horizontal="horizontal"
            placeholder="...zB. Gepflegt"
            invalidFeedback="Bitte geben Sie hier einen Text ein!"
            :isValid="inputValueValid"
            required
            custom>
          <template v-slot:append>
            <CButton type="button" color="secondary" @click="addToList">Hinzufügen</CButton>
          </template>
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'ExtraStepsAddStepList',
  props: {
    label: String,
    description: String,
    horizontal: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      changeTypeOptions: [
        { label: 'Prozentual (%)', value: 'percentual', sign: '%' },
        { label: 'Pauschal (€)', value: 'fixed', sign: '€' }
      ],
      answerList: [...this.value],
      changeType: 'percentual',
      inputValue: null,
      changeValue: null,
      inputValueValid: null,
      changeValueValid: null
    }
  },
  methods: {
    addToList () {
      if (this.validateValues()) {
        this.answerList.push({
          answer: this.inputValue,
          change: this.changeValue,
          changeType: this.changeType
        })
        this.clearValues()
        this.$emit('input', this.answerList)
      }
    },
    removeFromList (index) {
      this.answerList.splice(index, 1)
      this.$emit('input', this.answerList)
    },
    validateValues () {
      this.inputValueValid = typeof this.inputValue === 'string' && this.inputValue.length > 0
      this.changeValueValid = typeof this.changeValue === 'string' && this.changeValue.length > 0
      return this.inputValueValid && this.changeValueValid
    },
    clearValues () {
      this.inputValue = ''
      this.changeValue = ''
      this.inputValueValid = null
      this.changeValueValid = null
    },
    determineChangeType (value) {
      value = value || this.changeType
      return this.changeTypeOptions.find(opt => opt.value === value)
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.answerList = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.image-input-field {
  margin: 14px 0;
}
.preview-image {
  height: 80px;
  width: auto;
}
.extra-steps-add-step-list-wrapper {
  display: inline-block;
  margin-bottom: 8px;
  .col-sm-6:first-child {
    padding-right: 10px;
  }
  .col-sm-6:last-child {
    padding-left: 10px;
  }
}
.extra-steps-add-step-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
}
.extra-steps-add-step-list-item-icon {
  background-color: #9f3a38;
  display: inline-flex;
  width: 26px;
  height: 26px;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 8px;
}
.extra-steps-add-step-list-item-icon:hover {
  background-color: #992c29;
}
</style>
