<template>
  <div class="extra-steps-component">
    <h3>Individuelle Fragen <small><CBadge color="success" shape="pill">Neu</CBadge></small></h3>
    <p>Legen Sie eigene Fragen im Leadgenerator an. Sie haben die Möglichkeit individuelle Zu & Abschläge für jede Antwortmöglichkeit zu definieren (z.B. wenn Sie den Objektzustand abfragen, -15% bei renovierungsbedürftig, 0% bei mittel, +5% bei gepflegt)</p>
    <div class="extra-steps-wrapper">
      <div v-for="extraStep in extraSteps" class="extra-step" @click="editExtraStep(extraStep)">
        <h4>{{ extraStep.title }}</h4>
        <hr/>
        <p style="font-weight: bold;margin-bottom: 6px;">{{ (extraStep.answers || []).length }} {{ (extraStep.answers || []).length === 1 ? 'Antwort' : 'Antworten' }}</p>
        <p style="font-size: 13px;font-style: italic">
          {{ extraStep.activeTypes.map(type => $commons.LEAD_TYPES[type.toUpperCase()].title).join(', ') }}
        </p>
      </div>
      <div class="add-extra-step" @click="modalIsAdd = true; showExtraStepModal = true">
        <CIcon name="cil-plus"/>
      </div>
    </div>
    <CModal
        :title="modalIsAdd ? 'Extra Schritt erstellen' : 'Extra Schritt bearbeiten'"
        :show.sync="showExtraStepModal"
        style="text-align: left"
    >
      <CInput
          label="Titel"
          v-model="modalContent.title"
          placeholder="...zB. Ist Ihre Immobilie renovierungsbedürftig?"
      />
      <ExtraStepsAddStepList
          label="Auswahlmöglichkeiten"
          :horizontal="false"
          v-model="modalContent.answers"
          description="Hinterlegen Sie individuelle Fragen zur Vorqualifizierung ihrer potenziellen Kunden"
      />
      <Checkbox
          label="Immobilientypen"
          description="Zu welchen Immobilientypen soll dieser Extra Schritt angezeigt werden?"
          name="activeTypes"
          :horizontal="false"
          v-model="modalContent.activeTypes"
          :options="[{label: 'Wohnung', value: 'apartment'}, {label: 'Haus', value: 'house'}, {label: 'Grundstück (keine Wertermittlung)', value: 'land'}, {label: 'Miete', value: 'rent'}, {label: 'Gewerbe (keine Wertermittlung)', value: 'business'}]"
      />
      <template v-slot:footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="cancelModal"
        >
          Abbrechen
        </button>
        <button
            v-if="!modalIsAdd"
            type="button"
            class="btn btn-danger"
            @click="deleteExtraStep"
        >
          Löschen
        </button>
        <button
            type="button"
            class="btn btn-success"
            @click="confirmModal"
        >
          Speichern!
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox"
import ExtraStepsAddStepList from "@/components/ExtraStepsAddStepList";
export default {
  name: "ExtraStepsComponent",
  components: { ExtraStepsAddStepList, Checkbox },
  created() {
    this.cancelModal() // reset
  },
  props: {
    value: Array
  },
  data () {
    return {
      showExtraStepModal: false,
      extraSteps: [...this.value],
      modalIsAdd: true,
      modalContent: null
    }
  },
  methods: {
    editExtraStep(extraStep) {
      this.modalIsAdd = false
      this.showExtraStepModal = true
      this.modalContent = extraStep
      this.$emit('input', this.extraSteps)
    },
    deleteExtraStep() {
      this.extraSteps = this.extraSteps.filter(ex => ex !== this.modalContent)
      this.$emit('input', this.extraSteps)
      this.cancelModal()
    },
    cancelModal() {
      this.showExtraStepModal = false
      this.modalContent = {
        title: '',
        answers: [],
        activeTypes: ['apartment', 'house', 'land', 'rent', 'business']
      }
    },
    confirmModal() {
      if (this.modalContent && this.modalContent.answers && this.modalContent.answers.length <= 0) {
        this.$toaster.makeToast('Fehlerhaft!', 'Bitte geben Sie mindestens eine Auswahlmöglichkeit ein.')
        return
      }
      if (this.modalIsAdd) {
        this.extraSteps.push(Object.assign({}, this.modalContent))
        this.$emit('input', this.extraSteps)
      }
      this.showExtraStepModal = false
    }
  },
  watch: {
    showExtraStepModal(newVal) {
      if (newVal === false) this.cancelModal()
    },
    value(newVal) {
      this.extraSteps = [...newVal]
    }
  }
}
</script>

<style lang="scss" scoped>
.extra-steps-wrapper {
  display: flex;
  background-color: #f6f6f6;
  padding: 18px;
  margin: 4px;
  border-radius: 4px;
  & > div {
    margin-right: 12px;
  }
}
.add-extra-step {
  display: flex;
  position: relative;
  border-radius: 4px;
  border: 2px dashed #cdcdcd;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;

  & > svg {
    width: 4rem !important;
    height: 4rem !important;
    fill: #cdcdcd;
    transition: all 0.2s;
  }
  &:hover {
    border: 2px dashed #b8b8b8;
    -webkit-font-smoothing: subpixel-antialiased;
    transform: scale(1.02) translate3d(0, -5px, 0);
    & > svg {
      fill: #b8b8b8;
      width: 4.1rem !important;
      height: 4.2rem !important;
    }
  }
}
.extra-step {
  display: flex;
  position: relative;
  background-color: #2b3438;
  color: #fff;
  border-radius: 4px;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  flex-direction: column;

  h4, h5, p {
    text-align: left;
    padding: 0 12px;
    width: 100%;
  }
  p {
    margin-bottom: 0.2rem;
  }
  hr {
    width: 90%;
    margin: 4px 12px 12px 12px;
    background-color: #fff;
  }
  &:hover {
    -webkit-font-smoothing: subpixel-antialiased;
    transform: scale(1.02) translate3d(0, -5px, 0);

  }
}
</style>
